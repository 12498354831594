.audio-init-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.95);
    z-index: 1000;
    gap: 1rem;
}

.init-audio-button {
    padding: 1rem 2rem;
    font-size: 1.2rem;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.init-audio-button:hover {
    background-color: #45a049;
}

.init-audio-text {
    color: #666;
    font-size: 0.9rem;
    text-align: center;
    max-width: 80%;
    margin: 0;
}

.full-page-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    backdrop-filter: blur(3px);
}

.overlay-content {
    text-align: center;
    color: white;
    padding: 2rem;
}

.overlay-content h2 {
    font-size: 2rem;
    margin: 0 0 1rem 0;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.overlay-content p {
    font-size: 1rem;
    margin: 0;
    opacity: 0.8;
}

.keyboard-player {
    position: relative;
    width: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.keyboard-player.disabled {
    opacity: 0.7;
    pointer-events: none;
}

.play-midi-button {
    padding: 1rem;
    font-size: 2.4rem;
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s ease;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    color: #858789;
    width: auto;
    margin: 1rem 0;
    min-height: 10px;
    -webkit-tap-highlight-color: transparent;
    outline: none;
    position: relative;
}

.play-midi-button:focus {
    outline: none;
}

.play-midi-button .rose {
    font-family: monospace;
    position: relative;
    right: 1px;
    z-index: 2;
    font-size: 1.2em;
}

.play-midi-button .arrows {
    position: absolute;
    text-align: center;
    font-family: monospace;
    white-space: pre;
    animation: arrowFade 0.5s ease-in-out;
    letter-spacing: 0.3em;
    /* transform: translateY(1px); */
}

@keyframes arrowFade {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.7;
    }
}

.play-midi-button:disabled {
    cursor: not-allowed;
    opacity: 0.7;
}

.play-midi-button .rose-frame {
    font-family: monospace;
    white-space: pre;
    text-align: center;
    width: 100%;
    animation: fadeInOut 2s ease-in-out infinite;
}

@keyframes fadeInOut {
    0%, 100% {
        opacity: 0.7;
    }
    50% {
        opacity: 1;
    }
} 