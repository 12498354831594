.audio-player {
    display:flex;
    flex-direction: row;
    // margin: 0 0 100px 0;

    a {
        text-decoration: none;
        color: white;
        opacity: 0.8;
        transition: 0.4s all ease;

        img {
            height: 25px;
            margin-right: 10px;
        }
    }



    .spotifyButton {
        display: inline-block;
        padding: 0px 20px;
        border-radius: 5px;
        background-color: #343a40;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;
        flex-direction: row;
        text-decoration: none;
    }

    a:hover {
        opacity: 1;
    }



    .audio-controls {
        display: flex;
        flex-direction: column;

        .buttons {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items:  center;
            width: 150px;
        }

        button {
            border: none;
            background-color: transparent;
            width: 29px;
            height: 29px;
            display: inline-block;
            cursor: pointer;
            position:relative;
            // opacity: 0.8;
            transition: all 0.3s ease;

            &:first-child:hover {
                transform: translateX(-2px);
            }

            &:last-child:hover {
                transform: translateX(2px);
            }
            
            img {
                width: 100%;
                vertical-align: middle;
            }
        }

        button:hover {
            opacity: 1;
        }

        .playpause {
            background-color: #9D9EEC;
            border-radius: 100000px;
            width: 45px;
            height: 45px;
            display: inline-block;
            cursor: pointer;
            text-align: center;
            transition: all 0.3s ease;
            touch-action: manipulation;

            &:hover {
                background-color: rgb(137, 137, 228);
            }

            img {
                width: 13px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .play {
            transition: all 0.3s ease-out;

            img {
                left: 53% !important;
            }
        }

        .play:hover {
            transition: all 0.3s ease-out;
            
        }

        .play:hover::before {
            transition: all 0.8s ease;
            position: absolute;
            content: "Got a sec? Listen to some of my music!";
            display: inline-block;
            width: 300px;
            padding: 12px 0px;
            background-color: #1e1e1e;
            border-radius: 7px;
            font-weight: 500;
            color: white;
            font-size: 15px;
            left: -50px;
            top: 60px;
            box-shadow: 0 13px 0px 0px #32325d00, 0 8px 17px -8px #0000004d;
            // font-family: 'Open Sans', sans-serif;
            
            
        }

        .play:hover::after {
            transition: all 0.8s ease;
            position: absolute;
            top: 55px;
            left: 17px;
            content: "";
            display: block;
            width: 10px;
            height: 10px;
            background-color: #1e1e1e;
            transform: rotate(45deg);
        }
        input {
            height: 6px;
            -webkit-appearance: none;
            width: 100%;
            // margin-bottom: 10px;
            margin-top: 22px;
            border-radius: 8px;
            transition: background 0.2s ease;
            cursor: pointer;
            background: transparent;
            
        }

        input[type=range]:focus {
            outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
        }

                /* Special styling for WebKit/Blink */
        input[type=range]::-webkit-slider-thumb {
            -webkit-appearance: none;
            border: 1px solid #000000;
            height: 11px;
            width: 11px;
            border-radius: 300000px;
            background: #ffffff;
            cursor: pointer;
            border: 0.5px solid #FFFFFF;
            box-sizing: border-box;
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);   
        }

        input[type=range]::-moz-range-thumb {
            -webkit-appearance: none;
            border: 1px solid #000000;
            height: 11px;
            width: 11px;
            border-radius: 300000px;
            background: #ffffff;
            cursor: pointer;
            border: 0.5px solid #FFFFFF;
            box-sizing: border-box;
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);   
        }

        input[type=range]::-ms-thumb {
            -webkit-appearance: none;
            border: 1px solid #000000;
            height: 11px;
            width: 11px;
            border-radius: 300000px;
            background: #ffffff;
            cursor: pointer;
            border: 0.5px solid #FFFFFF;
            box-sizing: border-box;
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);   
        }

        .progress {
            transition: 0.3s all ease;
            opacity: 0;
        }

        .popup {
            
        }
    }

    .track-info {
        display: flex;
        flex-direction: row;
        height: 45px;
        padding-left: 25px;
        opacity: 0;
        transition: all 0.3s ease;

        img {
            height: 100%;
            border-radius: 7px;
        }

        h2 {
            font-weight: normal;
            margin: 0;
            position: relative;
            left: 15px;
            top: 12px;
            font-size: 15px;
            color: #333333;
        }
    }

    .visible {
        opacity: 1 !important;
    }

}