// Post-specific styles that extend the about page structure
.post {

    font-family: 'Lora', serif;

    .section-title {
        padding-right: var(--gutter);
    }
  .section-content {
    h1, h2, h3, h4, h5, h6, p {
      font-family: 'Lora', serif;
      margin-bottom: 2rem;
      line-height: 2.6rem
    }

    h2 {
        font-family: 'Inter', sans-serif;
    }

    p {
        font-weight: 400;
    }

    // Remove margin from the last element to avoid extra space at the bottom
    *:last-child {
      margin-bottom: 0;
    }

    // Add spacing for other common markdown elements
    ul, ol, blockquote, pre, table {
      margin-bottom: 1.5rem;
    }
  }
} 