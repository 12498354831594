// Mobile floating player styles
.mobile-floating-player {
  display: none; // Hide by default on desktop
  
  @media (max-width: 768px) {
    display: block; // Show on mobile
    position: fixed;
    bottom: 20px; // Add space at the bottom
    left: 15px;
    right: 15px;
    z-index: 1000;
    background-color: #fff;
    border-radius: 12px; // Rounded corners for floating effect
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15); // More pronounced shadow
    transition: all 0.3s ease; // For all transitions including position
    overflow: hidden; // Ensure children don't overflow the container
    
    // Home page specific positioning (20px higher)
    &.on-home-page {
      bottom: 40px; // Position 20px higher on home page
      
      &.expanded {
        // Keep the same relative offset when expanded on home page
        transform: translateY(-20px); // Same slide up effect
      }
    }
    
    // Collapsed (simple) player styles
    &.collapsed {
      transform: translateY(0); // Default position
      
      .simple-player {
        width: 100%;
        
        .simple-player-content {
          display: flex;
          align-items: center;
          padding: 15px 15px 12px; // More padding for comfort
          
          .player-album {
            width: 45px;
            height: 45px;
            min-width: 45px;
            margin-right: 15px;
            
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 6px;
            }
          }
          
          .player-info {
            flex: 1;
            margin-right: 15px;
            white-space: nowrap;
            overflow: hidden;
            
            .song-title {
              font-weight: 600;
              font-size: 15px;
              margin-bottom: 3px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            
            .song-artist {
              font-size: 13px;
              color: #666;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          
          .play-pause-btn {
            background: none;
            border: none;
            width: 40px; // Larger button
            height: 40px; // Larger button
            min-width: 40px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            background-color: #333;
            color: white !important; // Force white color
            padding: 0;
            
            &:hover {
              background-color: #555;
            }
            
            i {
              font-size: 15px; // Larger icon
            }
          }
        }
        
        .mini-progress-bar {
          height: 3px; // Slightly thicker for better visibility
          width: 100%;
          background-color: #eee;
          position: relative;
          overflow: hidden; // Contain the progress fill
          border-bottom-left-radius: 12px; // Match container radius
          border-bottom-right-radius: 12px; // Match container radius
          
          .progress-fill {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            background-color: #333;
            transition: width 0.1s linear;
            border-bottom-right-radius: 3px; // Rounded edge for the progress
          }
        }
      }
    }
    
    // Expanded player styles
    &.expanded {
      transform: translateY(-20px); // Slide up when expanded
      max-height: calc(100vh - 40px); // Prevent overflow
      overflow: auto;
      
      .expanded-player {
        padding: 10px 0 20px;
        
        .header-bar {
          display: flex;
          justify-content: center;
          padding: 5px 0 10px;
          
          .collapse-btn {
            background: none;
            border: none;
            width: 40px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            color: #666;
            
            &:hover {
              color: #333;
            }
            
            i {
              font-size: 16px;
            }
          }
        }
        
        .music-player {
          border-radius: 0;
          box-shadow: none;
          padding: 0 20px;
          
          .player-header {
            margin-bottom: 20px;
            
            .player-album {
              width: 70px;
              height: 70px;
              min-width: 70px;
              margin-right: 20px;
            }
            
            .player-info {
              .song-title {
                font-size: 18px;
              }
              
              .song-artist {
                font-size: 15px;
              }
              
              .song-links {
                margin-top: 10px;
                .external-link {
                  font-size: 12px;
                  padding: 5px 10px;
                }
              }
            }
          }
          
          .player-controls {
            .control-buttons {
              margin-bottom: 15px;
              
              .control-btn {
                width: 48px; // Much larger buttons
                height: 48px; // Much larger buttons
                min-width: 48px;
                min-height: 48px;
                margin: 0 15px;
                color: #333 !important; // Force dark color
                
                i {
                  font-size: 18px; // Larger icons
                }
                
                &.play-btn {
                  width: 54px; // Even larger play button
                  height: 54px;
                  min-width: 54px;
                  min-height: 54px;
                  background-color: #333;
                  color: white !important; // Force white color
                  
                  i {
                    font-size: 20px;
                  }
                }
              }
            }
            
            .seek-container {
              .time {
                font-size: 13px;
              }
              
              .seek-slider {
                height: 5px; // Thicker slider for easier interaction
              }
            }
          }
        }
      }
    }
  }
} 