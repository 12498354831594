.keyboard-player {
    text-align: center;
    padding: 10px;
    position: fixed;
    bottom: 15%; // Adjust this value to position above the Marquee
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 400px; // Half of previous 800px
    z-index: 10;
    
    .init-message {
        position: absolute;
        top: -20px;
        left: 0;
        right: 0;
        text-align: center;
        font-size: 14px;
        color: #666;
        opacity: 0.8;
        animation: fadeInOut 2s infinite;
        pointer-events: none;
    }

    @keyframes fadeInOut {
        0%, 100% { opacity: 0.5; }
        50% { opacity: 1; }
    }

    .visual-keyboard {
        position: relative;
        margin: 0 auto;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px; // Half of previous 8px

        .white-keys, .black-keys {
            display: flex;
            gap: 4px; // Half of previous 8px
            position: relative;
        }

        .keyboard-key {
            width: 20px; // Half of previous 40px
            height: 20px; // Half of previous 40px
            border-radius: 3px; // Half of previous 6px
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
            font-size: 10px; // Reduced from 14px
            color: #666;
            user-select: none;
            box-shadow: 0 1px 2px rgba(0,0,0,0.1);
            border: 1px solid rgba(0,0,0,0.1);
        }

        .black-key {
            // Adjusted spacing for black keys to align with piano layout
            &:nth-child(1) { margin-left: -12px; } // w - between a and s
            &:nth-child(2) { margin-left: 0px; }  // e - between s and d
            &:nth-child(3) { margin-left: 24px; } // t - between f and g
            &:nth-child(4) { margin-left: 0px; }  // y - between g and h
            &:nth-child(5) { margin-left: 0px; }  // u - between h and j
            &:nth-child(6) { margin-left: 24px; } // o - between k and l (reduced from 28px)
            &:nth-child(7) { margin-left: 0px; }  // p - after l
        }
    }

    .keyboard-instructions {
        margin-top: 12px;
        // padding: 8px;
        background: rgba(0, 0, 0, 0.05);
        border-radius: 4px;

        p {
            margin: 3px 0;
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
            font-size: 10px;
            color: #666;
        }
    }

    .play-midi-button {
        // margin-top: 15px;
        // padding: 6px 12px;
        font-size: 14px;
        // color: #333;
        border-radius: 4px;
        cursor: pointer;
        transition: all 0.2s ease;
        font-family: 'Inter',-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;

        &:hover {
            // background-color: #e4e4e4;
            // font-weight: 900;
            transform: translateY(-1px);
        }

        &:active {
            transform: translateY(0);
        }
    }
}

@media (max-width: 768px) {
    .keyboard-player {
        bottom: 15%; // Adjusted position for mobile
        max-width: 360px; // Slightly wider on mobile to accommodate larger keys

        .visual-keyboard {
            gap: 5px; // Slightly larger gap for mobile

            .white-keys, .black-keys {
                gap: 5px; // Slightly larger gap for mobile
            }

            .keyboard-key {
                width: 24px;  // 1.2x larger
                height: 24px; // 1.2x larger
                font-size: 12px; // Slightly larger font
            }

            .black-key {
                // Adjusted spacing for black keys on mobile (1.2x)
                &:nth-child(1) { margin-left: -14px; }
                &:nth-child(2) { margin-left: 0px; }
                &:nth-child(3) { margin-left: 29px; }
                &:nth-child(4) { margin-left: 0px; }
                &:nth-child(5) { margin-left: 0px; }
                &:nth-child(6) { margin-left: 29px; }
                &:nth-child(7) { margin-left: 0px; }
            }
        }

        .play-midi-button {
            // margin-top: 20px; // Slightly more spacing on mobile
            // padding: 8px 16px; // Larger touch target
            font-size: 14px; // Slightly larger font
        }
    }
} 