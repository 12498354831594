.song-context-marquee {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  margin: 2px 0;
  max-width: 100%; // Ensure it doesn't overflow parent
  user-select: none; // Prevent text selection
  cursor: default; // Change cursor to default instead of text selection
}

.song-context-content {
  display: inline-flex;
  animation: song-context-marquee linear infinite;
  color: #999; // Lighter gray
  font-size: 0.7em; // Smaller text
  max-width: 100%; // Ensure content doesn't overflow
  text-overflow: ellipsis; // Fallback for non-animated state
  will-change: transform; // Optimize animation performance
}

.text-segment {
  padding-right: 20px; // Reduced from 50px to 20px for smaller gaps
  white-space: nowrap;
}

@keyframes song-context-marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

// // Pause animation on hover
// .song-context-marquee:hover .song-context-content {
//   animation-play-state: paused;
// } 