.marquee-container {
    position: fixed;
    bottom: auto;
    bottom: 10px;
    left: 0;
    width: 100%;
    height: 30px;
    background-color: transparent;
    overflow: hidden;
    white-space: nowrap;
    z-index: 999;
    border: none;
    pointer-events: auto; /* Allow text selection and clicks */
}

.marquee-track {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.marquee-content {
    display: inline-flex;
    animation: marquee 30s linear infinite;
    padding: 5px 0;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif; 
    font-weight: 500;
    font-size: var(--size-small);
    color: #333;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    will-change: transform;
    transition: all 0.2s ease;
}

/* Pause animation on hover instead of slowing down */
.marquee-container:hover .marquee-content {
    animation-play-state: paused;
}

.marquee-item {
    display: inline-flex;
    align-items: center;
    margin-right: 10px;
    white-space: nowrap;
    cursor: default;
}

.selectable-text {
    user-select: text;
    cursor: default;
    display: inline-flex;
    align-items: center;
}

.separator {
    margin-left: 10px;
    opacity: 0.6;
    user-select: none; /* Prevent separator selection */
    pointer-events: none; /* Prevent separator interaction */
    display: inline-flex;
    align-items: center;
}

.spotify-link {
    cursor: pointer !important;
    text-decoration: underline;
    color: inherit;
}

.spotify-link:hover {
    opacity: 0.7;
}

.marquee-content a {
    color: inherit;
    text-decoration: underline;
    transition: opacity 0.2s;
    cursor: pointer;
}

.marquee-content a:hover {
    opacity: 0.7;
}

@keyframes marquee {
    0% {
        transform: translate3d(0, 0, 0);
    }
    100% {
        transform: translate3d(-33.33%, 0, 0);
    }
}

@media (max-width: 768px) {
    .marquee-container {
        bottom: 10px;
        height: 25px;
        display: flex;
        align-items: center;
    }
    
    .marquee-content {
        font-size: var(--size-small);
        padding: 4px 0;
        display: inline-flex;
        align-items: center;
    }
    
    /* Improved Spotify alignment for mobile */
    .spotify-item {
        line-height: 1.2;
        display: inline-flex;
        align-items: center;
        height: 100%;
    }
    
    .spotify-logo {
        display: inline-flex;
        align-items: center;
        vertical-align: middle;
        height: 100%;
    }
    
    .spotify-logo svg {
        width: 14px;
        height: 14px;
        vertical-align: middle;
        position: relative;
        top: -0.5px;
    }
    
    .spotify-content {
        display: inline-flex;
        align-items: center;
        line-height: 1.2;
        height: 100%;
    }
    
    .marquee-item, 
    .selectable-text {
        line-height: 1.2;
        display: inline-flex;
        align-items: center;
        height: 100%;
    }
    
    /* Reset browser-specific defaults while preserving important spacing */
    .marquee-item *,
    .spotify-item *,
    .spotify-logo *,
    .spotify-content * {
        vertical-align: middle;
    }
    
    /* Preserve separator spacing */
    .separator {
        margin-left: 10px;
    }
}

.spotify-item {
    display: inline-flex;
    align-items: center;
}

.spotify-logo {
    display: inline-flex;
    align-items: center;
    margin-right: 6px;
    position: relative;
}

.spotify-logo svg {
    vertical-align: middle;
    position: relative;
    top: -1px;
}

.spotify-content {
    display: inline-flex;
    align-items: center;
} 