.home-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
}

.dvd-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
  animation: fadeIn 0.8s ease-out forwards;
}

.dvd-logo {
  pointer-events: auto;
  transition: transform 0.1s ease-out;
  
  &:hover {
    transform: scale(1.05);
  }
}

.headline-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 95vh;
  z-index: 5;
  position: relative;
  opacity: 0;
  animation: fadeIn 0.8s ease-out 0.2s forwards;

  @media (max-width: 768px) {
    min-height: 85vh;
    align-items: flex-start;
    padding-top: 20vh;
  }
}

.headline-text {
  font-family: 'Lora', serif;
  text-align: center;
  max-width: 450px;
  padding: 0 20px;
  
  p {
    font-size: var(--size-large);
    font-weight: 700;
    margin: 0;
    line-height: 1.2;
    animation: softerGlow 5s infinite;
    
    @media (max-width: 768px) {
      font-size: var(--size-large);
    }
    
    @media (max-width: 480px) {
      font-size: var(--size-large);
    }
  }
  
  a {
    text-decoration: underline;
    color: inherit;
    transition: opacity 0.2s;
    
    &:hover {
      opacity: 0.7;
    }
  }
}

// Soft glow effect for DVD logo and headline text
@keyframes softGlow {
  0% {
    filter: blur(0.5px) brightness(1);
  }
  50% {
    filter: blur(1px) brightness(1.1);
  }
  100% {
    filter: blur(0.5px) brightness(1);
  }
}

// Soft glow effect for DVD logo and headline text
@keyframes softerGlow {
  0% {
    filter: blur(0.1px) brightness(1);
  }
  50% {
    filter: blur(0.2px) brightness(1.1);
  }
  100% {
    filter: blur(0.1px) brightness(1);
  }
}



.dvd-logo svg {
  transition: fill 0.5s ease;
  animation: softGlow 3s infinite;
}


/* Mobile styles */
@media screen and (max-width: 768px) {
  
  .nav-links {
    width: 50%;
  }
}

// Add fade-in animations
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInCentered {
  from {
    opacity: 0;
    transform: translateX(-50%) translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
}

@keyframes fadeInOnly {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

// Apply animations to components
header {
  opacity: 0;
  animation: fadeInOnly 0.4s ease-out 0.4s forwards;
}

.keyboard-player {
  opacity: 0;
  animation: fadeInCentered 0.4s ease-out 1s forwards;
  // animation: fadeInCentered 0s ease 0s forwards;
}

.marquee-container {
  opacity: 0;
  animation: fadeInOnly 0.4s ease-out 0.4s forwards;
}

.about {
  opacity: 0;
  animation: fadeInOnly 0.4s ease-out 0.4s forwards;
}