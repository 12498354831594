@import url(https://fonts.googleapis.com/css?family=Press+Start+2P);

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

$home-color: #fafafa;

.home {
  // .glitch:hover {
  //   background: transparent;
  //   mix-blend-mode: lighten;
  //   border-radius: 1000px;
  //   overflow: hidden;
  //   // color: transparent;
  //   // z-index: -10;
  // }

  // .glitch:hover::after {
  //   background: $home-color;
  //   // backdrop-filter: opacity(0.7);
  //   // color: transparent;
  //   // z-index: -10;
  // }

  // .glitch:hover::before {
  //   background: $home-color;
  //   // backdrop-filter: opacity(0.7);
  //   // color: transparent;
  //   // z-index: -10;
  // }

    // while I can't figure out home screen hack: 
    .glitch:hover::after, .glitch:hover::before {
      display: none;
    }

    .glitch:hover {
      animation: none;
      background: transparent;
    }
}

.glitch:hover {
  position: relative;
  margin: 0 auto;
  background: #fff;
}

.glitch:hover::before,
.glitch:hover::after {
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: alternate-reverse;
  overflow: hidden;
  position: absolute;
  top: 0;
  clip: rect(0, 900px, 0, 0);
  content: attr(data-text);
  // margin: 0 2px 0 2px;
}

.glitch:hover::after {
  animation-name: glitch-animation;
  animation-duration: 4s;
  left: 2px;
  text-shadow: -1px 0 #ea28c7;
  background: #fff;
}

.glitch:hover::before {
  animation-name: glitch-animation-2;
  animation-duration: 6s;
  left: -2px;
  text-shadow: 1px 0 #53c7eb;
  background: #fff;
}

@mixin rect($top, $bottom) {
  clip: rect($top, 9999px, $bottom, 0);
}

/* Expanded Animations */
@keyframes glitch-animation {
  0% { @include rect(42px, 44px); }
  5% { @include rect(12px, 59px); }
  10% { @include rect(48px, 29px); }
  15.0% { @include rect(42px, 73px); }
  20% { @include rect(63px, 27px); }
  25% { @include rect(34px, 55px); }
  30.0% { @include rect(86px, 73px); }
  35% { @include rect(20px, 20px); }
  40% { @include rect(26px, 60px); }
  45% { @include rect(25px, 66px); }
  50% { @include rect(57px, 98px); }
  55.0% { @include rect(5px, 46px); }
  60.0% { @include rect(82px, 31px); }
  65% { @include rect(54px, 27px); }
  70% { @include rect(28px, 99px); }
  75% { @include rect(45px, 69px); }
  80% { @include rect(23px, 85px); }
  85.0% { @include rect(54px, 84px); }
  90% { @include rect(45px, 47px); }
  95% { @include rect(37px, 20px); }
  100% { @include rect(4px, 91px); }
}

@keyframes glitch-animation-2 {
  0% { @include rect(65px, 100px); }
  5% { @include rect(52px, 74px); }
  10% { @include rect(79px, 85px); }
  15.0% { @include rect(75px, 5px); }
  20% { @include rect(67px, 61px); }
  25% { @include rect(14px, 79px); }
  30.0% { @include rect(1px, 66px); }
  35% { @include rect(86px, 30px); }
  40% { @include rect(23px, 98px); }
  45% { @include rect(85px, 72px); }
  50% { @include rect(71px, 75px); }
  55.0% { @include rect(2px, 48px); }
  60.0% { @include rect(30px, 16px); }
  65% { @include rect(59px, 50px); }
  70% { @include rect(41px, 62px); }
  75% { @include rect(2px, 82px); }
  80% { @include rect(47px, 73px); }
  85.0% { @include rect(3px, 27px); }
  90% { @include rect(26px, 55px); }
  95% { @include rect(42px, 97px); }
  100% { @include rect(38px, 49px); }
}