html {
    font-size: 62.5%;
}

body {
    margin: 0;
    background-color: #FFFFFF;
    font-size: 2rem;
    font-size: var(--size-body, 2rem);

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    // overflow-x: hidden;

    max-width: 100vw;
    padding: 0;

    // &:max-width(768px) {
    //     overflow-x: hidden;
    // }

    // @media (max-width: 768px) {
    //     overflow-x: hidden;
    // }
}

img, picture, video {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

a {
    text-decoration: underline;
    color: #191919;

    &:hover {
        color: #999999;
        transition: color 0.2s ease;
    }
}

* {
    -webkit-tap-highlight-color: rgba(0,0,0,0)!important;
    // transition: 0.2s all ease-in;
}

*, :after, :before {
    box-sizing: inherit;
}

::selection {
    background-color: #424242;
    background-color: var(--color-text, var(--color-black-off, #424242));
    color: #fff;
    color: var(--color-bg, var(--color-white, #fff));
}


:root {
    --size-xsmall: 1rem;
    --size-small: 1.4rem;
    --size-body: 1.8rem;
    --size-medium: 2.2rem;
    --size-large: 3rem;
    --size-xlarge: 5rem;
    --mediaIndexWidth: calc(40px + 1.5ch);
    --numberOfColumns: 12;
    --margin: 16px;
    --gutter: 16px;
    --column: calc((1 / var(--numberOfColumns))*((100* var(--vw)) -(var(--margin)* 2) -((var(--numberOfColumns) - 1)*(var(--gutter)))));
}

/* font-family: 'Lora', serif;
font-family: 'Open Sans', sans-serif; */

canvas {
    position: fixed;
    left: 0;
    top: 0;
    z-index: -1;
}



.ad {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    // user-select: none;

    display: inline;
    position: relative;

    div {
        position:relative;
    }
}

header {
    left: 0;
    padding: 1.5rem;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    z-index: 100;
    font-family: Inter;
    font-weight: 700;
    letter-spacing: -2%;
    font-family: "Inter", sans-serif;
    font-size: var(--size-body);

    .bolder {
        transition: all 0.2s ease;
        
        &:hover {
            color: #999 ;
            // text-decoration: underline
        }
    }
    
    // &::before {
    //     content: '';
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     right: 0;
    //     bottom: 0;
    //     // bottom: -2rem;
    //     background: rgba(255, 255, 255, 1);
    //     -webkit-backdrop-filter: blur(7px);
    //     backdrop-filter: blur(7px);
    //     mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.8) 80%, rgba(0, 0, 0, 0) 100%);
    //     // -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.8) 80%, rgba(0, 0, 0, 0) 100%);
    //     -webkit-mask-image: linear-gradient(to bottom, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 0) 100%);
    //     z-index: -1;
    // }

    // @media (max-width: 768px) {
    //     &::before {
    //         -webkit-mask-image: linear-gradient(to bottom, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%);
    //     }
    // }

    div {
        align-items: flex-start;
        display: flex;
        justify-content: space-between;
        position: relative;
    }

    a {
        pointer-events: auto;
        color: inherit;
        text-decoration: none;
        transition: color 0.2s ease;

        &:hover {
            color: #999;
        }
    }
}

.nav-links {
    display: flex;
    justify-content: space-between;
    width: calc(1/3 * 100%);
    color: #191919;

    ul {
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            font-size: var(--size-body);
            position: relative;

            &:not(:last-child):after {
                content: ',';
                display: inline;
                margin-right: .25em;
                transition: color 0.2s ease;
            }

            a {
                transition: color 0.2s ease;
                outline: none;
            }

            &:hover {
                a {
                    color: #999;
                }

                &:not(:last-child):after {
                    color: #999;
                }
            }

            a.activeLinkStyle {
                color: #999;
                // transition: 0.2s all ease-out;
            }

            &:has(> a.activeLinkStyle):not(:last-child):after {
                color: #999;
            }
        }
    }
}

.page {
    min-height: 100vh;
    overscroll-behavior: none;
}

.gallery {

    background-color: #ffffff;
    height: 100vh;
    // height: calc(var(--vh, 1vh)* 100);
    position: relative;
    width: 100%;

    .gallery-navigation {            
        height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
    }

    .prev {
        cursor: w-resize;
        left: 0;
    }

    .next {
        cursor: e-resize;
        right: 0;
    }

    .prev, .next 
    {
        bottom: 0;
        position: absolute;
        top: 0;
        width: 50%;
        z-index: 1;
    }

    .gallery-item {
        align-items: center;
        // cursor: pointer;
        display: flex;
        height: 100%;
        justify-content: center;
        left: 0;
        position: absolute;
        top: 0;
        // width: 100%;
        // padding: 13rem;

        img {
            height: 100%;
            -o-object-fit: contain;
            object-fit: contain;
            -o-object-position: center;
            object-position: center;
            width: 100%;
        }

        .meta {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;
            position: absolute;

            z-index: 2;

            bottom: 0;
            padding: 1.5rem 0;

            font-size: 16px;
            font-weight: 700;

            .description {
                position: relative;
                left: 1.5rem;
            }

            .white {
                color: white !important
                
            }

            a {
                position: relative;
                right: 1.5rem;  
            }



            p {
                margin: 0;
                padding: 0;
            }
            
        }
    }

    .layout-fill {

        height: 100%;
        width: 100%;

        img {
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
            -o-object-position: center;
            object-position: center;
            width: 100%;
        }

        .landscape {
            position: relative;
            display: block;
        }

    }

    .layout-margin {
        // position: absolute;
        .portrait {
            display: block;
            position: relative;
            width: 100%;
            height: 100%;
        }
    }

    .layout-landscape {
        padding: 0vh 18vw;

        .landscape {
            img {
                    height: 100%;
                    max-width: 140rem;
                    -o-object-fit: contain;
                    object-fit: contain;
                    -o-object-position: center;
                    object-position: center;
                    width: 100%;
            }
        }
    }
}

.about {
    // display: block;
    // width: 100%;
    // position: relative;
    padding: 1.5rem;

    .bio {
        font-family: 'Lora', serif;
        padding-top: 4rem;
        margin: 0;

        h1 {
            font-weight: 300;
            font-size: var(--size-large)
        }

        p {
            font-size: var(--size-medium)
        }
    }

    .photo-container {
        display: block;
        position: relative;
    }
    .photo-scroll {
        display: flex;
        flex-direction: row;
        // position: absolute;
        width: 100%;
        overflow-x: scroll;
        scroll-behavior: smooth;
        padding-bottom: 2rem;

        .photo {
            opacity: 0.7;
            img {
                height: 25rem;
                
            }

            img:not(:last-child) {
                margin-right: 1rem;
            }
            p {
                color: gray;
                font-size: var(--size-small);
                margin: 0;
                padding: 0;
            }

            &:hover {
                opacity: 1;
            }
        }

        


    }

    .social-links {
        
        h3 {
            font-size: var(--size-small);
            color: #858789;
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            list-style-type: none;
            margin: 0;
            padding: 0;
            list-style: none;
    
            li:not(:last-child):after {
                content: ',';
                display: inline;
                margin-right: .25em;
            }

            a {
                text-decoration: none;
                color: none;
                font-size: var(--size-medium);
                font-family: 'Lora', serif;
            }
        }
        
    }

}

.now {
    padding: 1.5rem;
    // display: block;

    .page-header {
        display: block;
        padding-top: 4rem;
        font-family: 'Lora';
        
        h1 {
            font-weight: 300;
            font-size: var(--size-medium)
        }

        p {
            font-size: var(--size-medium)
        }

        h3 {
            font-size: var(--size-small);
            color: #858789;
            font-family: 'Inter',
        }
    }
    

 
}

    @-webkit-keyframes ani {
        0%{background-position:0% 99%}
        50%{background-position:100% 2%}
        100%{background-position:0% 99%}
    }
    @keyframes ani {
        0%{background-position:0% 99%}
        50%{background-position:100% 2%}
        100%{background-position:0% 99%}
    }

    .seafoam {
        &:before {
            animation: ani 1s ease infinite;
            background: linear-gradient(231deg, #00e5ff 0%, #3CD3AD 51%, #00e5ff 100%);
          }
          &:hover {
            &:before {
            background: linear-gradient(231deg, #00e5ff 0%, #3CD3AD 51%, #00e5ff 100%);
            }
          }
        
    }

    .sorbet {
        &:before {
            animation: ani 1s ease infinite;
            background: linear-gradient(231deg, #ff6ca4 0%, #ff9b7c 51%, #ff6ca4 100%);
          }
          &:hover {
            &:before {
            background: linear-gradient(231deg, #ff6ca4 0%, #ff9b7c 51%, #ff6ca4 100%);
            }
          }
        
    }

    // .smooth {
    //     background: linear-gradient(231deg, #c491ff 0%, #568ad8 51%, #c491ff 100%);
    // }

    // .rose {
    //     background: linear-gradient(231deg, #ffafbd 0%, #ffc3a0 51%, #ffafbd 100%);
    // }

    // .airplane {
    //     background: linear-gradient(231deg, #40bcd9 0%, #6dd5ed 51%, #40bcd9 100%);
    // }

.photos {
    display: flex;
    clear: both;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;

    img {
        width: 32.4%;
        display: inline-block;
        border-radius: 3px;
    }
}

.content {
    padding: 0;
    font-family: "Inter", serif;
    font-weight: 400;
    padding-top: 50px;

    .caption {
        color: #B8B8B8;
        font-size: 14px;
        font-family: "Inter", serif;
    }

    h3 {
        font-family: 'Inter', sans-serif;
        color: #343a40;
        font-weight: 600;
    }

    p {
        line-height: 27px;
        color: #343a40;
    }

    b {
        font-weight: 600;
        color: #343a40;
        font-size: 17px;
    }

    a {
        text-decoration: none;
        color: #9d9df2;
        font-weight: 700;
        transition: 0.1s all ease;

        &:hover {
            color: #8888f0;
            text-decoration: none;
        }
    }

    .description {
        margin-top: 0;
    }

    ul {
        // padding-inline-start: 40px;
        list-style: none;
        padding: 0;
        margin: 0;
        padding-inline-start: 0;

        li { 
            padding-left: 2em;
            text-indent: -.7em;
            line-height: 27px;
            padding-bottom: 0.85em;
            color: #343a40;
            vertical-align:middle
        }

        li::before {
            content: "•";
            padding-right: 5px;
            color: #8084a7;
            position: relative;
            right: 10px;
        }
    }

    

}

footer { 
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    position: relative;
    align-items: center;
    bottom: 10px;

    
    .social-links {
        float: right;
        position: relative;
        top: 5px;
    }

        .social-links img {
            width: 18px;
            margin: 0 5px;
            opacity: 0.6;
            transition: 0.3s all ease;
        }

            .social-links img:hover {
                opacity: 0.9;
                transform: rotate(-25deg);
            }

        .social-links a {
            margin: 0 10px;
        }
}


@media (max-width: 768px) {


    // .about, .now {
    //     margin: 10px 10px 0 10px !important;
    //     padding: 0 20px !important;
    // }

    .nav {
        display: block;
        position: relative;
        position: relative;
        top: 20px;

        a:before {
            height: 0;
            width: 0;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0px 0 0 0;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-content: center;
            align-items: center;
        }
    }

    .nav li {
        // margin-top: 10px;
        margin-right: 0 !important;
    }

    .header {
        padding-bottom: 50px;
    }

    footer {
        padding: 20px 30px 40px 30px;
        bottom: 0px;

        .social-links {
            float: none;

            a:first-child {
                margin-left: 0;
            }
        }
    }

    // .audio-player {
    //     display: none !important;
    // }

    .gray {
        left: 0px !important;
    }


}

.gray {
    color: #a2a2a2;
    position: relative;
    left: 3px;
}

.links {
    font-size: 13px;
    color: #a2a2a2;
    position: relative;
    bottom: 2px;
}

/* @media handheld {
.my-image:hover {
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    transform: none;
}
} */

.animation-container {
  width: 75%;
  margin: 0 auto;
  border: 1px solid black;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 20px;
  
  canvas {
    width: 100%;
    height: auto;
    display: block;
  }
}
