.about {
    .about-section {
        #player {
            width: 100%;
            margin: 2rem 0;
            position: relative;
            text-align: center;

            .info {
                margin: 2rem 0;
                h1 {
                    color: var(--color-text, #2d2d2d);
                    font-weight: 400;
                    margin: 0;
                    font-size: var(--size-body);
                    font-family: 'Inter', sans-serif;
                }
            }

            .playPauseContainer {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                margin: 2rem auto;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                background: linear-gradient(125deg, #cd7ef1, #8af0ec);
                background-size: 400% 400%;
                transition: all 0.2s ease-out;
                box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);

                animation: gradientAnimation 7s ease infinite;

                img {
                    width: 15px;
                    height: 15px;
                }

                &:hover {
                    transform: translateY(-2px);
                    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
                }
            }

            .progressBarContainer {
                margin: 2rem 0;
            }
        }
    }
}

.collectedBars {
    width: 100%;
    height: 180px;
    margin: 2rem auto;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.singleBar {
    background-color: rgb(254, 68, 133);
    border-radius: 20px;
    width: 7px;
    height: 7px;
    display: inline-block;
    margin: 0 5px;
    transition: height 0.1s ease;
}

@keyframes gradientAnimation {
    0% { background-position: 0% 50% }
    50% { background-position: 100% 50% }
    100% { background-position: 0% 50% }
}

// Track list styles
.track-list {
  margin: 10px 0 0 0;
  
  .track-item {
    display: flex;
    align-items: center;
    padding: 10px;
    // margin-bottom: 10px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
    
    &.active {
      background-color: rgba(0, 0, 0, 0.1);
    }
    
    .track-image {
      width: 50px;
      height: 50px;
      min-width: 50px;
      margin-right: 15px;
      
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
      }
    }
    
    .track-info {
      flex: 1;
      
      .track-name {
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 4px;
      }
      
      .track-artist {
        font-size: 14px;
        color: #666;
      }
    }
  }
}

// Music player styles
.music-player {
  // width: 75%;
  padding: 15px;
  border-radius: 10px;
  overflow: hidden;
  // background-color: #f8f8f8;
  box-shadow: 0 0px 1px rgba(0, 0, 0, 1);
  font-family: 'Inter', sans-serif;
  
  .player-header {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
  }
  
  .player-album {
    width: 70px;
    height: 70px;
    min-width: 70px;
    margin-right: 15px;
    
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 6px;
      // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    }
  }
  
  .player-info {
    flex: 1;
    
    .song-title {
      font-weight: 700;
      font-size: 18px;
      margin-bottom: 5px;
    }
    
    .song-artist {
      font-size: 15px;
      color: #555;
      margin-bottom: 3px;
    }
    
    .song-context {
      font-size: 13px;
      color: #777;
      margin-bottom: 10px;
      // text-transform: uppercase;
      line-height: 1.4;
    }
    
    .song-links {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      margin-top: 8px;
      
      .external-link {
        display: inline-block;
        font-size: 12px;
        padding: 4px 8px;
        background-color: #eee;
        color: #333;
        text-decoration: none;
        border-radius: 4px;
        transition: all 0.2s;
        
        &:hover {
          background-color: #333;
          color: white;
        }
      }
    }
  }
  
  .player-controls {
    .control-buttons {
      display: flex;
      justify-content: center;
      margin-bottom: 15px;
      
      .control-btn {
        background: none;
        border: none;
        width: 36px;
        height: 36px;
        min-width: 36px;
        min-height: 36px;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin: 0 10px;
        cursor: pointer;
        transition: background-color 0.2s;
        padding: 0;
        position: relative;
        
        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }
        
        &.play-btn {
          background-color: #333;
          color: white;
          
          &:hover {
            background-color: #555;
          }
        }
        
        i {
          font-size: 14px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: auto;
          height: auto;
          line-height: 1;
        }
      }
    }
    
    .seek-container {
      display: flex;
      align-items: center;
      
      .time {
        font-size: 12px;
        color: #777;
        width: 35px;
        
        &.current {
          text-align: right;
          margin-right: 5px;
        }
        
        &.total {
          text-align: left;
          margin-left: 5px;
        }
      }
      
      .seek-slider {
        flex: 1;
        height: 4px;
        -webkit-appearance: none;
        background-color: #ddd;
        border-radius: 2px;
        outline: none;
        
        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: #333;
          cursor: pointer;
        }
      }
    }
    
    .volume-container {
      display: flex;
      align-items: center;
      
      i {
        font-size: 14px;
        color: #555;
        margin: 0 10px;
      }
      
      .volume-slider {
        flex: 1;
        height: 4px;
        -webkit-appearance: none;
        background-color: #ddd;
        border-radius: 2px;
        outline: none;
        
        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #333;
          cursor: pointer;
        }
      }
    }
  }
}

// Styles for the sidebar player
.about-sidebar-player {
  // margin-top: 20px;
  width: 100%;
  
  @media (max-width: 768px) {
    // Hide the player in the sidebar on mobile
    display: none;
  }
}

// Global music player styles
.global-music-player {
  display: block;
  width: 100%;
}

// Mobile floating player
.mobile-floating-player {
  display: none; // Hide by default on desktop
  
  @media (max-width: 768px) {
    display: block; // Show on mobile
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: #fff;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    
    .music-player {
      border-radius: 0;
      box-shadow: none;
      padding: 8px;
      
      .player-header {
        margin-bottom: 8px;
        
        .player-album {
          width: 50px;
          height: 50px;
          min-width: 50px;
          margin-right: 10px;
        }
        
        .player-info {
          .song-title {
            font-size: 16px;
          }
          
          .song-artist {
            font-size: 13px;
          }
          
          .song-links {
            margin-top: 5px;
            .external-link {
              font-size: 10px;
              padding: 3px 6px;
            }
          }
        }
      }
      
      .player-controls {
        .control-buttons {
          margin-bottom: 8px;
          
          .control-btn {
            width: 30px;
            height: 30px;
            min-width: 30px;
            min-height: 30px;
            margin: 0 8px;
          }
        }
        
        .seek-container {
          .time {
            font-size: 10px;
          }
        }
      }
    }
  }
} 